import loadable from '@loadable/component';

import { CmsApiRichTextBlockData } from 'modules/cms/api/types/blocks/CmsApiRichTextBlock';
import { CmsBlockMapping } from 'modules/cms/blocks/CmsBlockMapping';
import { CmsMappedBlockWidth } from 'modules/cms/mappers/CmsMappedBlockWidth';
import { hydrateOnDemand } from 'utils/hydrateOnDemand/hydrateOnDemand';

export const CmsRichTextBlockMapping: CmsBlockMapping<CmsApiRichTextBlockData> =
  {
    invariant: [
      {
        prop: 'html',
        msg: 'Html data required for rich text',
      },
    ],
    width: CmsMappedBlockWidth.NARROW,
    Component: hydrateOnDemand(
      loadable(() => import('./CmsRichTextBlock'), {
        resolveComponent: (module) => module.CmsRichTextBlock,
      }),
    ),
  };
