import loadable from '@loadable/component';

import { CmsApiVideoBlockData } from 'modules/cms/api/types/blocks/CmsApiVideoBlock';
import { CmsBlockMapping } from 'modules/cms/blocks/CmsBlockMapping';
import { CmsMappedBlockWidth } from 'modules/cms/mappers/CmsMappedBlockWidth';
import { hydrateOnDemand } from 'utils/hydrateOnDemand/hydrateOnDemand';

export const CmsVideoBlockMapping: CmsBlockMapping<CmsApiVideoBlockData> = {
  invariant: [
    {
      prop: 'videoUrl',
      msg: 'URL required for video',
    },
    {
      prop: 'width',
      msg: 'width required for video',
    },
  ],
  width: CmsMappedBlockWidth.NARROW,
  Component: hydrateOnDemand(
    loadable(() => import('./CmsVideoBlock'), {
      resolveComponent: (module) => module.CmsVideoBlock,
    }),
  ),
};
