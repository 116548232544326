type Args = {
  size: { type: 'width'; width: number } | { type: 'height'; height: number };
  aspectRatio: { width: number; height: number };
};

export function getFilestackCalculatedImageSize({ size, aspectRatio }: Args): {
  width: number;
  height: number;
} {
  const aspectRatioMultiplier = aspectRatio.width / aspectRatio.height;

  if (size.type === 'width') {
    return {
      width: size.width,
      height: Math.floor(size.width / aspectRatioMultiplier),
    };
  }

  return {
    width: Math.floor(size.height * aspectRatioMultiplier),
    height: size.height,
  };
}
