import queryString from 'query-string';

import { RouteComponentProps } from 'utils/withRouter';

export function getParam(
  l: Location | RouteComponentProps['location'],
  param: string,
) {
  const q = queryString.parse(l.search)[param];
  return q;
}
