import loadable from '@loadable/component';

import { CmsApiSplitContentBlockData } from 'modules/cms/api/types/blocks/CmsApiSplitContentBlock';
import { CmsBlockMapping } from 'modules/cms/blocks/CmsBlockMapping';
import { CmsMappedBlockWidth } from 'modules/cms/mappers/CmsMappedBlockWidth';
import { hydrateOnDemand } from 'utils/hydrateOnDemand/hydrateOnDemand';

export const CmsSplitContentBlockMapping: CmsBlockMapping<CmsApiSplitContentBlockData> =
  {
    invariant: [],
    width: CmsMappedBlockWidth.NARROW,
    Component: hydrateOnDemand(
      loadable(() => import('./CmsSplitContentBlock'), {
        resolveComponent: (module) => module.CmsSplitContentBlock,
      }),
    ),
  };
