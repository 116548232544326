import loadable from '@loadable/component';

import { CmsApiImageBlockData } from 'modules/cms/api/types/blocks/CmsApiImageBlock';
import { CmsBlockMapping } from 'modules/cms/blocks/CmsBlockMapping';
import { CmsMappedBlockWidth } from 'modules/cms/mappers/CmsMappedBlockWidth';
import { hydrateOnDemand } from 'utils/hydrateOnDemand/hydrateOnDemand';

export const CmsImageBlockMapping: CmsBlockMapping<CmsApiImageBlockData> = {
  invariant: [
    {
      prop: 'image',
      msg: 'Image data required for image',
    },
    {
      prop: 'width',
      msg: 'width required for image',
    },
  ],
  width: CmsMappedBlockWidth.NARROW,
  Component: hydrateOnDemand(
    loadable(() => import('./CmsImageBlock'), {
      resolveComponent: (module) => module.CmsImageBlock,
    }),
  ),
};
