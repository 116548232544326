import {
  CmsApiBlock,
  CmsApiBlockType,
} from 'modules/cms/api/types/CmsApiBlock';
import { CmsMappedBlock } from 'modules/cms/mappers/CmsMappedBlock';

const blocksWithDividers: CmsApiBlockType[] = [
  'RECOMMENDED_ACTIONS',
  'RECOMMENDED_JOBS',
  'THREE_SECTION_SLIDER',
  'FOUR_ARTICLES_HIGHLIGHT',
  'THREE_STEP_CTA',
];

export function mustHaveDivider<TBlock extends CmsApiBlock>(
  block: CmsMappedBlock<TBlock>,
) {
  return blocksWithDividers.includes(block.type) && !block.mustNotHaveDivider;
}
