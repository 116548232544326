import loadable from '@loadable/component';

import { CmsApiChecklistBlockData } from 'modules/cms/api/types/blocks/CmsApiChecklistBlock';
import { CmsBlockMapping } from 'modules/cms/blocks/CmsBlockMapping';
import { CmsMappedBlockWidth } from 'modules/cms/mappers/CmsMappedBlockWidth';

export const CmsChecklistBlockMapping: CmsBlockMapping<CmsApiChecklistBlockData> =
  {
    invariant: [],
    width: CmsMappedBlockWidth.NARROW,
    Component: loadable(() => import('./CmsChecklistBlock'), {
      resolveComponent: (module) => module.CmsChecklistBlock,
    }),
  };
