import loadable from '@loadable/component';

import {
  CmsApiCtaHeroBlockData,
  CmsApiCtaHeroBlockLayout,
} from 'modules/cms/api/types/blocks/CmsApiCtaHeroBlock';
import { CmsBlockMapping } from 'modules/cms/blocks/CmsBlockMapping';
import { CmsMappedBlockWidth } from 'modules/cms/mappers/CmsMappedBlockWidth';
import { hydrateOnDemand } from 'utils/hydrateOnDemand/hydrateOnDemand';

export const CmsCtaHeroBlockMapping: CmsBlockMapping<CmsApiCtaHeroBlockData> = {
  invariant: [
    {
      prop: 'text',
      msg: 'Text data required for call to action',
    },
    {
      prop: 'image',
      msg: 'Image data required for call to action',
    },
  ],
  width: (data) => {
    if (data.layout === CmsApiCtaHeroBlockLayout.COMPACT)
      return CmsMappedBlockWidth.NARROW;

    return CmsMappedBlockWidth.FULL;
  },
  Component: hydrateOnDemand(
    loadable(() => import('./CmsCtaHeroBlock'), {
      resolveComponent: (module) => module.CmsCtaHeroBlock,
    }),
  ),
};
