import styled, { css } from 'styled-components';

import { cssBreakpoints, gutterWidth, maxWidth } from 'theme/theme';

const gutterWidthVar = '--cms-body-holder-gutter-width';

const cmsBodyHolderRegularWidthCss = css`
  max-width: calc(740px + var(${gutterWidthVar}) * 2);
  padding: 0 var(${gutterWidthVar});
`;

const cmsBodyHolderRegularWideWidthCss = css`
  max-width: calc(952px + var(${gutterWidthVar}) * 2);
  padding: 0 var(${gutterWidthVar});
`;

const cmsBodyHolderExpandedWidthCss = css`
  padding: 0 var(${gutterWidthVar});

  @media screen and (min-width: ${cssBreakpoints.mdUp}) {
    max-width: ${maxWidth[2]}px;
  }

  @media screen and (min-width: ${cssBreakpoints.lgUp}) {
    max-width: ${maxWidth[3]}px;
  }
`;

export enum CmsBodyHolderWidth {
  NARROW_LAYOUT = 'NARROW_LAYOUT',
  NARROW_LAYOUT_WIDE = 'NARROW_LAYOUT_WIDE',
  PAGE_LAYOUT = 'PAGE_LAYOUT',
  FULL = 'FULL',
}

export const CmsBodyHolder = styled.div<{
  $width: CmsBodyHolderWidth;
}>`
  width: 100%;
  margin: 0 auto;

  ${gutterWidthVar}: ${gutterWidth / 2}px;

  @media screen and (min-width: ${cssBreakpoints.lgUp}) {
    ${gutterWidthVar}: ${gutterWidth}px;
  }

  ${(props) => {
    switch (props.$width) {
      case CmsBodyHolderWidth.NARROW_LAYOUT:
        return cmsBodyHolderRegularWidthCss;
      case CmsBodyHolderWidth.NARROW_LAYOUT_WIDE:
        return cmsBodyHolderRegularWideWidthCss;
      case CmsBodyHolderWidth.PAGE_LAYOUT:
        return cmsBodyHolderExpandedWidthCss;
      case CmsBodyHolderWidth.FULL:
        return '';
    }
  }}
`;
