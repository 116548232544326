import { ApiFilestackImageData } from 'api/types/ApiFilestackImageData';

type Args = {
  data: ApiFilestackImageData;
  width?: number;
  height?: number;
};

export function getFilestackImageOriginalSize({
  data,
  width,
  height,
}: Args): { width: number; height: number } | undefined {
  if (data.width && data.height)
    return { width: data.width, height: data.height };

  // Immediately after uploading an image to Filestack the image doesn't have
  // its size properties set yet, that only happens after the queue item for that
  // image is processed on the backend.
  //
  // In that case, just assume that the image has the dimensions provided
  // so that we can calculate the 2x size and display a crispy image
  if (width && height) return { width, height };

  return undefined;
}
