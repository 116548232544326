import loadable from '@loadable/component';

import { CmsApiRecommendedActionsBlockData } from 'modules/cms/api/types/blocks/CmsApiRecommendedActionsBlock';
import { CmsBlockMapping } from 'modules/cms/blocks/CmsBlockMapping';
import { CmsMappedBlockWidth } from 'modules/cms/mappers/CmsMappedBlockWidth';
import { hydrateOnDemand } from 'utils/hydrateOnDemand/hydrateOnDemand';

const CmsRecommendedActionsBlockLoadable = hydrateOnDemand(
  loadable(() => import('./CmsRecommendedActionsBlock'), {
    resolveComponent: (module) => module.CmsRecommendedActionsBlock,
  }),
);

export const CmsRecommendedActionsBlockMapping: CmsBlockMapping<CmsApiRecommendedActionsBlockData> =
  {
    invariant: [],
    width: CmsMappedBlockWidth.NARROW,
    Component: CmsRecommendedActionsBlockLoadable,
  };
