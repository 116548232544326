import loadable from '@loadable/component';

import { CmsApiSupportContactFormBlockData } from 'modules/cms/api/types/blocks/CmsApiSupportContactFormBlock';
import { CmsBlockMapping } from 'modules/cms/blocks/CmsBlockMapping';
import { CmsMappedBlockWidth } from 'modules/cms/mappers/CmsMappedBlockWidth';
import { hydrateOnDemand } from 'utils/hydrateOnDemand/hydrateOnDemand';

export const CmsSupportContactFormBlockMapping: CmsBlockMapping<CmsApiSupportContactFormBlockData> =
  {
    invariant: [],
    width: CmsMappedBlockWidth.FULL,
    Component: hydrateOnDemand(
      loadable(() => import('./CmsSupportContactFormBlock'), {
        resolveComponent: (module) => module.CmsSupportContactFormBlock,
      }),
    ),
  };
