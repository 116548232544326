import loadable from '@loadable/component';

import { CmsApiAnchorBlockData } from 'modules/cms/api/types/blocks/CmsApiAnchorBlock';
import { CmsBlockMapping } from 'modules/cms/blocks/CmsBlockMapping';
import { CmsMappedBlockWidth } from 'modules/cms/mappers/CmsMappedBlockWidth';
import { hydrateOnDemand } from 'utils/hydrateOnDemand/hydrateOnDemand';

export const CmsAnchorBlockMapping: CmsBlockMapping<CmsApiAnchorBlockData> = {
  invariant: [
    {
      prop: 'anchor',
      msg: 'Anchor required for anchor',
    },
  ],
  width: CmsMappedBlockWidth.NARROW,
  Component: hydrateOnDemand(
    loadable(() => import('./CmsAnchorBlock'), {
      resolveComponent: (module) => module.CmsAnchorBlock,
    }),
  ),
};
