import loadable from '@loadable/component';

import { CmsApiFlashCardQuizBlockData } from 'modules/cms/api/types/blocks/CmsApiFlashCardQuizBlock';
import { CmsBlockMapping } from 'modules/cms/blocks/CmsBlockMapping';
import { CmsMappedBlockWidth } from 'modules/cms/mappers/CmsMappedBlockWidth';
import { hydrateOnDemand } from 'utils/hydrateOnDemand/hydrateOnDemand';

export const CmsFlashCardsQuizBlockMapping: CmsBlockMapping<CmsApiFlashCardQuizBlockData> =
  {
    invariant: [],
    width: CmsMappedBlockWidth.NARROW,
    Component: hydrateOnDemand(
      loadable(() => import('./CmsFlashCardQuizBlock'), {
        resolveComponent: (module) => module.CmsFlashCardQuizBlock,
      }),
    ),
  };
