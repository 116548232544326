import loadable from '@loadable/component';

import { CmsApiGradFairGradProgramLinksBlockData } from 'modules/cms/api/types/blocks/CmsApiGradFairGradProgramLinksBlock';
import { CmsBlockMapping } from 'modules/cms/blocks/CmsBlockMapping';
import { CmsMappedBlockWidth } from 'modules/cms/mappers/CmsMappedBlockWidth';
import { hydrateOnDemand } from 'utils/hydrateOnDemand/hydrateOnDemand';

const CmsGradFairGradProgramLinksBlockLoadable = hydrateOnDemand(
  loadable(() => import('./CmsGradFairGradProgramLinksBlock'), {
    resolveComponent: (module) => module.CmsGradFairGradProgramLinksBlock,
  }),
);

export const CmsGradFairGradProgramLinksBlockMapping: CmsBlockMapping<CmsApiGradFairGradProgramLinksBlockData> =
  {
    invariant: [],
    width: CmsMappedBlockWidth.NARROW,
    Component: CmsGradFairGradProgramLinksBlockLoadable,
  };
