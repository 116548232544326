import { CmsApiPage } from 'modules/cms/api/types/CmsApiPage';
import { isDefined } from 'utils/functional/type';

type Props = {
  content: CmsApiPage;
};

export function getContentFirstRealBlockIndex({ content }: Props) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let firstRealBlockIndex: any;

  content.contentBlocks.forEach((block, i) => {
    if (!isDefined(firstRealBlockIndex) && block.type !== 'SCROLL_TRACKING') {
      firstRealBlockIndex = i;
    }
  });

  return firstRealBlockIndex;
}
