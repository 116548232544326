import loadable from '@loadable/component';

import { CmsApiTwoTilesBlockData } from 'modules/cms/api/types/blocks/CmsApiTwoTilesBlock';
import { CmsBlockMapping } from 'modules/cms/blocks/CmsBlockMapping';
import { CmsMappedBlockWidth } from 'modules/cms/mappers/CmsMappedBlockWidth';
import { hydrateOnDemand } from 'utils/hydrateOnDemand/hydrateOnDemand';

export const CmsTwoTilesBlockMapping: CmsBlockMapping<CmsApiTwoTilesBlockData> =
  {
    invariant: [
      {
        prop: 'tileOneHeadline',
        msg: 'Tile One Heading required for Two Tiles',
      },
      {
        prop: 'tileTwoHeadline',
        msg: 'Tile Two Heading required for Two Tiles',
      },
    ],
    width: CmsMappedBlockWidth.NARROW,
    Component: hydrateOnDemand(
      loadable(() => import('./CmsTwoTilesBlock'), {
        resolveComponent: (module) => module.CmsTwoTilesBlock,
      }),
    ),
  };
