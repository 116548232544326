import { getFilestackCalculatedImageSize } from './getFilestackCalculatedImageSize';

const MAX_FILESTACK_SIZE = 10000;

type Args = {
  originalSize: { width: number; height: number };
  partialSize: { width?: number; height?: number };
  aspectRatio: { width: number; height: number };
};

export function getFilestackScaledImageSize({
  originalSize,
  partialSize,
  aspectRatio,
}: Args): {
  width: number;
  height: number;
} {
  if (partialSize.width && partialSize.height) {
    return { width: partialSize.width, height: partialSize.height };
  }

  const calculatedSize = getFilestackCalculatedImageSize({
    size: partialSize.width
      ? { type: 'width', width: partialSize.width }
      : { type: 'height', height: partialSize.height || originalSize.height },
    aspectRatio,
  });

  const maxWidth = Math.min(
    calculatedSize.width,
    originalSize.width,
    MAX_FILESTACK_SIZE,
  );

  const maxHeight = Math.min(
    calculatedSize.height,
    originalSize.height,
    MAX_FILESTACK_SIZE,
  );

  const widthScaleDownFactor = maxWidth / calculatedSize.width;
  const heightScaleDownFactor = maxHeight / calculatedSize.height;

  const scaleDownFactor = Math.min(widthScaleDownFactor, heightScaleDownFactor);

  const width = Math.round(calculatedSize.width * scaleDownFactor);
  const height = Math.round(calculatedSize.height * scaleDownFactor);

  return { width, height };
}
