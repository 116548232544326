import loadable from '@loadable/component';

import { CmsApiFreeCodeBlockData } from 'modules/cms/api/types/blocks/CmsApiFreeCodeBlock';
import { CmsBlockMapping } from 'modules/cms/blocks/CmsBlockMapping';
import { CmsMappedBlockWidth } from 'modules/cms/mappers/CmsMappedBlockWidth';
import { hydrateOnDemand } from 'utils/hydrateOnDemand/hydrateOnDemand';

export const CmsFreeCodeBlockMapping: CmsBlockMapping<CmsApiFreeCodeBlockData> =
  {
    invariant: [
      {
        prop: 'code',
        msg: 'Code required',
      },
    ],
    width: CmsMappedBlockWidth.NARROW,
    Component: hydrateOnDemand(
      loadable(() => import('./CmsFreeCodeBlock'), {
        resolveComponent: (module) => module.CmsFreeCodeBlock,
      }),
    ),
  };
