import {
  CmsApiBlockForType,
  CmsApiBlockType,
} from 'modules/cms/api/types/CmsApiBlock';

import { CmsAccordionBlockMapping } from './Accordion/CmsAccordionBlockMapping';
import { CmsAnchorBlockMapping } from './Anchor/CmsAnchorBlockMapping';
import { CmsBlockquoteBlockMapping } from './Blockquote/CmsBlockquoteBlockMapping';
import { CategoryHighlightBlockMapping } from './CategoryHighlight/CategoryHighlightBlockMapping';
import { CmsChecklistBlockMapping } from './Checklist/CmsChecklistBlockMapping';
import { CmsBlockMapping } from './CmsBlockMapping';
import { CmsCtaHeroBlockMapping } from './CtaHero/CmsCtaHeroBlockMapping';
import { CmsDonateBlockMapping } from './Donate/CmsDonateBlockMapping';
import { CmsEmailCampaignSignupDoubleBlockMapping } from './EmailCampaignSignupDouble/CmsEmailCampaignSignupDoubleBlockMapping';
import { CmsEmailCampaignSignupSingleBlockMapping } from './EmailCampaignSignupSingle/CmsEmailCampaignSignupSingleBlockMapping';
import { CmsFlashCardsQuizBlockMapping } from './FlashCardQuiz/CmsFlashCardsQuizBlockMapping';
import { CmsFormBuilderBlockMapping } from './FormBuilder/CmsFormBuilderBlockMapping';
import { CmsFourArticlesHighlightBlockMapping } from './FourArticlesHighlight/CmsFourArticlesHighlightBlockMapping';
import { CmsFreeCodeBlockMapping } from './FreeCode/CmsFreeCodeBlockMapping';
import { CmsGradFairGradProgramLinksBlockMapping } from './GradFairGradProgramLinks/CmsGradFairGradProgramLinksBlockMapping';
import { CmsImageBlockMapping } from './Image/CmsImageBlockMapping';
import { CmsImageGalleryBlockMapping } from './ImageGallery/CmsImageGalleryBlockMapping';
import { CmsLogosBlockMapping } from './Logos/CmsLogosBlockMapping';
import { CmsPersonaQuizBlockMapping } from './PersonaQuiz/CmsPersonaQuizBlockMapping';
import { CmsRecentSalariesMapping } from './RecentSalaries/CmsRecentSalariesBlockMapping';
import { CmsRecommendedActionsBlockMapping } from './RecommendedActions/CmsRecommendedActionsBlockMapping';
import { CmsRecommendedJobsBlockMapping } from './RecommendedJobs/CmsRecommendedJobsBlockMapping';
import { CmsRichTextBlockMapping } from './RichText/CmsRichTextBlockMapping';
import { CmsScrollTrackingBlockMapping } from './ScrollTracking/CmsScrollTrackingBlockMapping';
import { CmsSignUpBlockMapping } from './SignUp/CmsSignUpBlockMapping';
import { CmsSplitContentBlockMapping } from './SplitContent/CmsSplitContentBlockMapping';
import { CmsStickyHeaderLinksBlockMapping } from './StickyHeaderLinks/CmsStickyHeaderLinksBlockMapping';
import { CmsSupportContactFormBlockMapping } from './SupportContactForm/CmsSupportContactFormBlockMapping';
import { CmsThreeSectionSliderBlockMapping } from './ThreeSectionSlider/CmsThreeSectionSliderBlockMapping';
import { CmsThreeStepCtaBlockMapping } from './ThreeStepCta/CmsThreeStepCtaBlockMapping';
import { CmsTileGridBlockMapping } from './TileGrid/CmsTileGridBlockMapping';
import { CmsTwoTilesBlockMapping } from './TwoTiles/CmsTwoTilesBlockMapping';
import { CmsVideoBlockMapping } from './Video/CmsVideoBlockMapping';

export const cmsBlockMappingByType: {
  [K in CmsApiBlockType]: CmsBlockMapping<CmsApiBlockForType<K>['data']>;
} = {
  ACCORDION: CmsAccordionBlockMapping,
  ANCHOR: CmsAnchorBlockMapping,
  BLOCKQUOTE: CmsBlockquoteBlockMapping,
  CATEGORY_HIGHLIGHT: CategoryHighlightBlockMapping,
  CTA_HERO: CmsCtaHeroBlockMapping,
  DONATE: CmsDonateBlockMapping,
  EMAIL_CAMPAIGN_SIGNUP_DOUBLE: CmsEmailCampaignSignupDoubleBlockMapping,
  EMAIL_CAMPAIGN_SIGNUP_SINGLE: CmsEmailCampaignSignupSingleBlockMapping,
  FLASH_CARD_QUIZ: CmsFlashCardsQuizBlockMapping,
  FORM_BUILDER: CmsFormBuilderBlockMapping,
  FOUR_ARTICLES_HIGHLIGHT: CmsFourArticlesHighlightBlockMapping,
  FREE_CODE: CmsFreeCodeBlockMapping,
  GRAD_FAIR_GRAD_PROGRAM_LINKS: CmsGradFairGradProgramLinksBlockMapping,
  IMAGE: CmsImageBlockMapping,
  IMAGE_GALLERY: CmsImageGalleryBlockMapping,
  LOGOS: CmsLogosBlockMapping,
  PERSONA_QUIZ: CmsPersonaQuizBlockMapping,
  RECENT_SALARIES: CmsRecentSalariesMapping,
  RECOMMENDED_ACTIONS: CmsRecommendedActionsBlockMapping,
  RECOMMENDED_JOBS: CmsRecommendedJobsBlockMapping,
  RICHTEXT: CmsRichTextBlockMapping,
  SCROLL_TRACKING: CmsScrollTrackingBlockMapping,
  SIGN_UP: CmsSignUpBlockMapping,
  SPLIT_CONTENT: CmsSplitContentBlockMapping,
  STICKY_HEADER_LINKS: CmsStickyHeaderLinksBlockMapping,
  SUPPORT_CONTACT_FORM: CmsSupportContactFormBlockMapping,
  THREE_SECTION_SLIDER: CmsThreeSectionSliderBlockMapping,
  THREE_STEP_CTA: CmsThreeStepCtaBlockMapping,
  TILE_GRID: CmsTileGridBlockMapping,
  TWO_TILES: CmsTwoTilesBlockMapping,
  VIDEO: CmsVideoBlockMapping,
  CHECKLIST: CmsChecklistBlockMapping,
};
