import {
  CmsApiBlock,
  CmsApiBlockType,
} from 'modules/cms/api/types/CmsApiBlock';
import { CmsMappedBlock } from 'modules/cms/mappers/CmsMappedBlock';

const blocksWithoutDividers: CmsApiBlockType[] = [
  'CTA_HERO',
  'STICKY_HEADER_LINKS',
  'EMAIL_CAMPAIGN_SIGNUP_SINGLE',
  'EMAIL_CAMPAIGN_SIGNUP_DOUBLE',
  'FORM_BUILDER',
  'FLASH_CARD_QUIZ',
  'SIGN_UP',
  'SCROLL_TRACKING',
  'RECOMMENDED_JOBS',
];

export function mustNotHaveDivider<TBlock extends CmsApiBlock>(
  block: CmsMappedBlock<TBlock>,
) {
  return blocksWithoutDividers.includes(block.type) || block.mustNotHaveDivider;
}
