import { CmsApiPage } from 'modules/cms/api/types/CmsApiPage';

type Props = {
  content: CmsApiPage;
};

export function getContentListingCounts({
  content,
}: Props): Record<string, number> | undefined {
  return content.heroSearch?.listingCounts;
}
