import loadable from '@loadable/component';

import { CmsApiEmailCampaignSignupDoubleBlockData } from 'modules/cms/api/types/blocks/CmsApiEmailCampaignSignupDoubleBlock';
import { CmsBlockMapping } from 'modules/cms/blocks/CmsBlockMapping';
import { CmsMappedBlockWidth } from 'modules/cms/mappers/CmsMappedBlockWidth';
import { hydrateOnDemand } from 'utils/hydrateOnDemand/hydrateOnDemand';

export const CmsEmailCampaignSignupDoubleBlockMapping: CmsBlockMapping<CmsApiEmailCampaignSignupDoubleBlockData> =
  {
    invariant: [
      {
        prop: 'headline',
        msg: 'Heading required for Email Campaign Signup Double',
      },
      {
        prop: 'toggleTextOne',
        msg: 'Toggle Text One required for Email Campaign Signup Double',
      },
      {
        prop: 'buttonTextOne',
        msg: 'Button Text One required for Email Campaign Signup Double',
      },
      {
        prop: 'iterableMessageTypeIdOne',
        msg: 'Iterable Message Type ID One required for Email Campaign Signup Double',
      },
      {
        prop: 'iterableListIdOne',
        msg: 'Iterable List ID One required for Email Campaign Signup Double',
      },
      {
        prop: 'toggleTextTwo',
        msg: 'Toggle Text Two required for Email Campaign Signup Double',
      },
      {
        prop: 'buttonTextTwo',
        msg: 'Button Text Two required for Email Campaign Signup Double',
      },
      {
        prop: 'iterableMessageTypeIdTwo',
        msg: 'Iterable Message Type ID Two required for Email Campaign Signup Double',
      },
      {
        prop: 'iterableListIdTwo',
        msg: 'Iterable List ID Two required for Email Campaign Signup Double',
      },
    ],
    width: CmsMappedBlockWidth.NARROW,
    Component: hydrateOnDemand(
      loadable(() => import('./CmsEmailCampaignSignupDoubleBlock'), {
        resolveComponent: (module) => module.CmsEmailCampaignSignupDoubleBlock,
      }),
    ),
  };
