import loadable from '@loadable/component';

import { CmsApiDonateBlockData } from 'modules/cms/api/types/blocks/CmsApiDonateBlock';
import { CmsBlockMapping } from 'modules/cms/blocks/CmsBlockMapping';
import { CmsMappedBlockWidth } from 'modules/cms/mappers/CmsMappedBlockWidth';
import { hydrateOnDemand } from 'utils/hydrateOnDemand/hydrateOnDemand';

const CmsDonateBlockLoadable = hydrateOnDemand(
  loadable(() => import('./CmsDonateBlock'), {
    resolveComponent: (module) => module.CmsDonateBlock,
  }),
);

export const CmsDonateBlockMapping: CmsBlockMapping<CmsApiDonateBlockData> = {
  invariant: [],
  width: CmsMappedBlockWidth.FULL,
  Component: CmsDonateBlockLoadable,
};
