import loadable from '@loadable/component';

import { CmsApiFormBuilderBlockData } from 'modules/cms/api/types/blocks/CmsApiFormBuilderBlock';
import { CmsBlockMapping } from 'modules/cms/blocks/CmsBlockMapping';
import { CmsMappedBlockWidth } from 'modules/cms/mappers/CmsMappedBlockWidth';
import { hydrateOnDemand } from 'utils/hydrateOnDemand/hydrateOnDemand';

export const CmsFormBuilderBlockMapping: CmsBlockMapping<CmsApiFormBuilderBlockData> =
  {
    invariant: [],
    width: CmsMappedBlockWidth.NARROW,
    Component: hydrateOnDemand(
      loadable(() => import('./CmsFormBuilderBlock'), {
        resolveComponent: (module) => module.CmsFormBuilderBlock,
      }),
    ),
  };
