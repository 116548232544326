import loadable from '@loadable/component';

import { CmsApiLogosBlockData } from 'modules/cms/api/types/blocks/CmsApiLogosBlock';
import { CmsBlockMapping } from 'modules/cms/blocks/CmsBlockMapping';
import { CmsMappedBlockWidth } from 'modules/cms/mappers/CmsMappedBlockWidth';

export const CmsLogosBlockMapping: CmsBlockMapping<CmsApiLogosBlockData> = {
  invariant: [],
  width: CmsMappedBlockWidth.NARROW,
  Component: loadable(() => import('./CmsLogosBlock'), {
    resolveComponent: (module) => module.CmsLogosBlock,
  }),
};
