import loadable from '@loadable/component';

import { CmsApiRecentSalariesBlockData } from 'modules/cms/api/types/blocks/CmsApiRecentSalariesBlock';
import { CmsBlockMapping } from 'modules/cms/blocks/CmsBlockMapping';
import { CmsMappedBlockWidth } from 'modules/cms/mappers/CmsMappedBlockWidth';
import { hydrateOnDemand } from 'utils/hydrateOnDemand/hydrateOnDemand';

export const CmsRecentSalariesMapping: CmsBlockMapping<CmsApiRecentSalariesBlockData> =
  {
    invariant: [],
    width: CmsMappedBlockWidth.FULL,
    Component: hydrateOnDemand(
      loadable(() => import('./CmsRecentSalariesBlock'), {
        resolveComponent: (module) => module.CmsRecentSalariesBlock,
      }),
    ),
  };
