import loadable from '@loadable/component';

import { CmsApiThreeStepCtaBlockData } from 'modules/cms/api/types/blocks/CmsApiThreeStepCtaBlock';
import { CmsBlockMapping } from 'modules/cms/blocks/CmsBlockMapping';
import { CmsMappedBlockWidth } from 'modules/cms/mappers/CmsMappedBlockWidth';
import { hydrateOnDemand } from 'utils/hydrateOnDemand/hydrateOnDemand';

export const CmsThreeStepCtaBlockMapping: CmsBlockMapping<CmsApiThreeStepCtaBlockData> =
  {
    invariant: [
      {
        prop: 'mainHeadline',
        msg: 'Main Heading required for Three Step Call-to-Action',
      },
    ],
    width: CmsMappedBlockWidth.NARROW,
    Component: hydrateOnDemand(
      loadable(() => import('./CmsThreeStepCtaBlock'), {
        resolveComponent: (module) => module.CmsThreeStepCtaBlock,
      }),
    ),
  };
