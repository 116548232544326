import { CSSProperties } from 'react';

import { ApiFilestackImageData } from 'api/types/ApiFilestackImageData';
import { Image } from 'components/Image/Image';
import { imageUrl } from 'utils/filestack';

import { getFilestackImageOriginalSize } from './getFilestackImageOriginalSize';
import { getFilestackImageSizes } from './getFilestackImageSizes';
import { getFilestackImageSourcesData } from './getFilestackImageSourcesData';
import { getFilestackImageSrcSet } from './getFilestackImageSrcSet';

type Props = {
  data: ApiFilestackImageData | null;
  mobileData?: ApiFilestackImageData;
  alt?: string;
  /**
   * The image will not stretch beyond its original size
   */
  width?: number;
  /**
   * The image will not stretch beyond its original size
   */
  height?: number;
  mobileAspectRatio?: { width: number; height: number };
  expandSize?: boolean;
  objectFit?: 'cover' | 'contain';
  objectPosition?: CSSProperties['objectPosition'];
  round?: boolean;
  /**
   * By default, when both width and height are provided, this component
   * uses `fit: crop` to respect the image ratio.
   *
   * This property changes that behavior.
   *
   * We're using `noCrop` instead of `fit` to limit the cropping options in
   * an attempt the make the component easier to use and maintain.
   */
  noCrop?: boolean;
  eagerLoad?: boolean;
  skeleton?: boolean;
  allowSVG?: boolean;
  'data-qa-id'?: string;
  style?: CSSProperties;
};

/**
 * This component does a series of calculations based on the original image size
 * and the requested size.
 *
 * These calculations are done to build the multiple src files necessary to ensure
 * the correct aspect ratio, image quality and size for each device.
 */
export function FilestackImage({
  data,
  mobileData,
  alt,
  width,
  height,
  mobileAspectRatio,
  expandSize,
  objectFit,
  objectPosition,
  round,
  noCrop,
  eagerLoad,
  skeleton,
  allowSVG,
  'data-qa-id': qaId,
  style,
}: Props) {
  if (!data) return null;

  const originalSize = getFilestackImageOriginalSize({ data, width, height });
  const fit = !noCrop && width && height ? 'crop' : 'max';
  const aspectRatio =
    width && height && fit === 'crop' ? { width, height } : originalSize;

  if (!originalSize || !aspectRatio || data?.mimetype?.includes('image/svg')) {
    return (
      <Image
        src={imageUrl({
          data,
          width,
          height,
          fit,
          insecureSVG: Boolean(allowSVG),
        })}
        expandSize={expandSize}
        objectFit={objectFit}
        objectPosition={objectPosition}
        round={round}
        aspectRatio={aspectRatio}
        eagerLoad={eagerLoad}
        skeleton={skeleton}
        data-qa-id={qaId}
        alt={alt || data?.altText || ''}
      />
    );
  }

  const { size1x, size2x } = getFilestackImageSizes({
    originalSize,
    height,
    width,
    aspectRatio,
  });

  const { src, srcSet } = getFilestackImageSrcSet({
    data,
    size1x,
    size2x,
    fit,
  });

  const sourcesData = getFilestackImageSourcesData({
    data,
    mobileData,
    fit,
    insecureSVG: allowSVG,
    originalSize,
    aspectRatio,
    mobileAspectRatio,
    maxWidth: size1x.width,
  }).filter((sourceData) => sourceData.srcSet !== srcSet);

  return (
    <Image
      src={src}
      srcSet={srcSet}
      sourcesData={sourcesData}
      width={size1x.width}
      height={size1x.height}
      aspectRatio={aspectRatio}
      expandSize={expandSize}
      objectFit={objectFit}
      objectPosition={objectPosition}
      round={round}
      eagerLoad={eagerLoad}
      skeleton={skeleton}
      data-qa-id={qaId}
      alt={alt || data?.altText || ''}
      style={style}
    />
  );
}
