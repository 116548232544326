import loadable from '@loadable/component';

import { CmsApiImageGalleryBlockData } from 'modules/cms/api/types/blocks/CmsApiImageGalleryBlock';
import { CmsBlockMapping } from 'modules/cms/blocks/CmsBlockMapping';
import { CmsMappedBlockWidth } from 'modules/cms/mappers/CmsMappedBlockWidth';
import { hydrateOnDemand } from 'utils/hydrateOnDemand/hydrateOnDemand';

export const CmsImageGalleryBlockMapping: CmsBlockMapping<CmsApiImageGalleryBlockData> =
  {
    invariant: [
      {
        prop: 'images',
        msg: 'Images required for Image Gallery',
      },
    ],
    width: CmsMappedBlockWidth.NARROW,
    Component: hydrateOnDemand(
      loadable(() => import('./CmsImageGalleryBlock'), {
        resolveComponent: (module) => module.CmsImageGalleryBlock,
      }),
    ),
  };
