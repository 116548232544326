import { trackEvent } from './trackEvent';

export function trackPageView(
  pageName: string,
  properties: Record<string, unknown> = {},
) {
  // Mixpanel assumes `id` refers to `userId`, so let's
  // rename to `listing_id` to keep things easier to parse.
  // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
  if (properties && properties.id) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line no-param-reassign
    properties.listing_id = properties.id;
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line no-param-reassign
    delete properties.id;
  }

  trackEvent(`Page View: ${pageName}`, properties);
}
