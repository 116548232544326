import loadable from '@loadable/component';

import { CmsApiSignUpBlockData } from 'modules/cms/api/types/blocks/CmsApiSignUpBlock';
import { CmsBlockMapping } from 'modules/cms/blocks/CmsBlockMapping';
import { CmsMappedBlockWidth } from 'modules/cms/mappers/CmsMappedBlockWidth';
import { hydrateOnDemand } from 'utils/hydrateOnDemand/hydrateOnDemand';

export const CmsSignUpBlockMapping: CmsBlockMapping<CmsApiSignUpBlockData> = {
  invariant: [],
  width: CmsMappedBlockWidth.FULL,
  Component: hydrateOnDemand(
    loadable(() => import('./CmsSignUpBlock'), {
      resolveComponent: (module) => module.CmsSignUpBlock,
    }),
  ),
};
