import loadable from '@loadable/component';

import { CmsApiScrollTrackingBlockData } from 'modules/cms/api/types/blocks/CmsApiScrollTrackingBlock';
import { CmsBlockMapping } from 'modules/cms/blocks/CmsBlockMapping';
import { CmsMappedBlockWidth } from 'modules/cms/mappers/CmsMappedBlockWidth';
import { hydrateOnDemand } from 'utils/hydrateOnDemand/hydrateOnDemand';

const CmsScrollTrackingBlockLoadable = hydrateOnDemand(
  loadable(() => import('./CmsScrollTrackingBlock'), {
    resolveComponent: (module) => module.CmsScrollTrackingBlock,
  }),
);

export const CmsScrollTrackingBlockMapping: CmsBlockMapping<CmsApiScrollTrackingBlockData> =
  {
    invariant: [],
    width: CmsMappedBlockWidth.FULL,
    Component: CmsScrollTrackingBlockLoadable,
  };
