import { ReactNode, useMemo } from 'react';

import { CmsApiBlock } from 'modules/cms/api/types/CmsApiBlock';
import {
  CmsBodyHolder,
  CmsBodyHolderWidth,
} from 'modules/cms/components/BodyHolder/CmsBodyHolder';
import { CmsMappedBlock } from 'modules/cms/mappers/CmsMappedBlock';
import { CmsMappedBlockWidth } from 'modules/cms/mappers/CmsMappedBlockWidth';

import { CmsBlockContainerBox } from './CmsBlockContainer.styled';

type Props<TBlock extends CmsApiBlock> = {
  id?: string;
  mappedBlock: CmsMappedBlock<TBlock>;
  prevBlockHasBackground: boolean;
  index: number;
  fullWidthLayout: boolean;
  showHeadingDivider: boolean;
  hideFirstHeadingDivider: boolean;
  children: ReactNode;
};

export function CmsMappedBlockContainer<TBlock extends CmsApiBlock>({
  id,
  mappedBlock,
  prevBlockHasBackground,
  index,
  fullWidthLayout,
  showHeadingDivider,
  hideFirstHeadingDivider,
  children,
}: Props<TBlock>) {
  const padding = useMemo(() => {
    let mobilePadding = 64;
    if (mappedBlock.block.type === 'THREE_STEP_CTA') {
      mobilePadding = 34;
    }
    return fullWidthLayout
      ? [index === 0 ? 36 : mobilePadding, null, 70]
      : [40, null, 60];
  }, [fullWidthLayout, index, mappedBlock.block.type]);

  const display = useMemo(() => {
    if (mappedBlock.isHidden) return 'none';
    if (mappedBlock.isInline) return 'inline';
    return null;
  }, [mappedBlock.isHidden, mappedBlock.isInline]);

  const mt = useMemo(() => {
    if (!mappedBlock.padding || mappedBlock.padding === 'DEFAULT')
      return padding;
    return 0;
  }, [mappedBlock.padding, padding]);

  const overflow = useMemo(() => {
    if (
      mappedBlock.block.type === 'SPLIT_CONTENT' &&
      mappedBlock.block.data.appearance === 'FULL_WIDTH'
    ) {
      return 'hidden';
    }

    return null;
  }, [mappedBlock]);

  const bodyHolderWidth = useMemo(() => {
    if (mappedBlock.width === CmsMappedBlockWidth.FULL) {
      return CmsBodyHolderWidth.FULL;
    }

    // All blocks are expanded when the content has full width layout
    if (fullWidthLayout || mappedBlock.type === 'SPLIT_CONTENT') {
      return CmsBodyHolderWidth.PAGE_LAYOUT;
    }

    if (
      mappedBlock.type === 'RECOMMENDED_JOBS' ||
      mappedBlock.type === 'RECOMMENDED_ACTIONS' ||
      mappedBlock.type === 'FOUR_ARTICLES_HIGHLIGHT'
    ) {
      return CmsBodyHolderWidth.NARROW_LAYOUT_WIDE;
    }

    return CmsBodyHolderWidth.NARROW_LAYOUT;
  }, [fullWidthLayout, mappedBlock.type, mappedBlock.width]);

  return (
    <CmsBlockContainerBox
      id={id}
      position="relative"
      display={display}
      overflow={overflow}
      width="100%"
      mb={
        !mappedBlock.padding || mappedBlock.padding === 'DEFAULT' ? padding : 0
      }
      mt={mt}
      py={mappedBlock.hasBackground ? padding : null}
      pt={
        index > 0 && mappedBlock.hasBackground && prevBlockHasBackground
          ? [0, 0, 0]
          : null
      }
      $showHeadingDivider={showHeadingDivider}
      $hideFirstHeadingDivider={hideFirstHeadingDivider}
      $fullWidthLayout={fullWidthLayout}
      $hasBackground={mappedBlock.hasBackground}
      $type={mappedBlock.type}
    >
      <CmsBodyHolder $width={bodyHolderWidth}>{children}</CmsBodyHolder>
    </CmsBlockContainerBox>
  );
}
