import loadable from '@loadable/component';

import { CmsApiBlockquoteBlockData } from 'modules/cms/api/types/blocks/CmsApiBlockquoteBlock';
import { CmsBlockMapping } from 'modules/cms/blocks/CmsBlockMapping';
import { CmsMappedBlockWidth } from 'modules/cms/mappers/CmsMappedBlockWidth';
import { hydrateOnDemand } from 'utils/hydrateOnDemand/hydrateOnDemand';

export const CmsBlockquoteBlockMapping: CmsBlockMapping<CmsApiBlockquoteBlockData> =
  {
    invariant: [
      {
        prop: 'quote',
        msg: 'Quote required for blockquote',
      },
    ],
    width: CmsMappedBlockWidth.NARROW,
    Component: hydrateOnDemand(
      loadable(() => import('./CmsBlockquoteBlock'), {
        resolveComponent: (module) => module.CmsBlockquoteBlock,
      }),
    ),
  };
