import { CmsApiPage } from 'modules/cms/api/types/CmsApiPage';

type Props = {
  content: CmsApiPage;
};

export function getContentShowListingCounts({ content }: Props): boolean {
  return Boolean(
    content && content.heroSearch && content.heroSearch.showCounts,
  );
}
