import loadable from '@loadable/component';

import { CmsApiCategoryHighlightBlockData } from 'modules/cms/api/types/blocks/CmsApiCategoryHighlightBlock';
import { CmsBlockMapping } from 'modules/cms/blocks/CmsBlockMapping';
import { CmsMappedBlockWidth } from 'modules/cms/mappers/CmsMappedBlockWidth';
import { hydrateOnDemand } from 'utils/hydrateOnDemand/hydrateOnDemand';

export const CategoryHighlightBlockMapping: CmsBlockMapping<CmsApiCategoryHighlightBlockData> =
  {
    invariant: [],
    width: CmsMappedBlockWidth.NARROW,
    Component: hydrateOnDemand(
      loadable(() => import('./CmsCategoryHighlightBlock'), {
        resolveComponent: (module) => module.CmsCategoryHighlightBlock,
      }),
    ),
  };
